import React, { Component } from "react"
import Link from "gatsby-link"
import Layout from "../../layouts/index"
import Scrollspy from "react-scrollspy"

class IsmCore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSideMenu: true,
    }
    this.toggleSideMenu = this.toggleSideMenu.bind(this)
    this.closeSideMenu = this.closeSideMenu.bind(this)
  }

  componentDidMount() {
    this.setState({ showSideMenu: window.innerWidth > 599 })
  }

  toggleSideMenu() {
    console.log("Toggle Side Menu")
    this.setState(prevState => ({
      showSideMenu: !prevState.showSideMenu,
    }))
  }

  closeSideMenu() {
    if (window.innerWidth < 599) {
      this.setState({ showSideMenu: false })
    }
  }

  render() {
    const coreNav = [
      "Luma服务管理概况",
      "事件管理",
      "服务请求",
      "问题管理",
      "变更管理",
      "知识管理",
      "服务目录",
      "配置管理",
      "资产管理和自动发现",
      "项目管理",
      "无需编码的配置",
      "全局检索",
      "集成",
      "多语言",
      "高级报告分析",
      "远程支持",
      "其它功能",
    ]

    return (
      <Layout path="pd">
        <div className="content-wrap">
          <div className="ism-core-sections">
            <section id="section-0">
              <h1>{coreNav[0]}</h1>
              <div className="ism-core-content">
                <h4>
                  智能服务管理（ISM）是Serviceai
                  Luma套件一个重要的产品，为客户提供更好的体验
                </h4>
                <p>
                  客户支持和信息技术系统是通过客户的满意度来衡量的，因此必须在服务等级协议（SLA）之内响应并准确地解决问题。为了实现这些目标，我们的智能服务管理（ISM）解决方案，采用自动化流程使用户达到最佳体验，涵盖从面向服务台的客户支持到全面的服务管理。
                </p>
                <p>
                  <strong>
                    通过智能服务管理，您可以准确、及时地服务您的客户，并确保您的IT资源满足您的公司和最终用户的业务需求。
                  </strong>
                </p>
                <h3>核心优势</h3>
                <ul>
                  <li>提升零接触和第一次接触的故障解决率</li>
                  <li>无代码的工作流程的修改</li>
                  <li>采用ITIL最佳实施方案，满足个性化需求的服务管理</li>
                  <li>可以从CRM系统中查看相关的数据</li>
                  <li>管理IT资产，前瞻性地避免问题</li>
                </ul>
                <p>
                  不灵活的软件会延缓工作速度、浪费资源。相反，功能强大、可灵活定制并且无需专业的开发人员或管理员，就能提供灵活、有效的服务，能持续降本增效。少花钱多办事，从而可以专注于更好地服务客户，实现您的商业目标。
                </p>
                <p>
                  Serviceai提供了一个健壮的、功能齐全的服务管理解决方案。我们有9个通过认证的ITIL流程可以为您提供卓越的支持。可以很灵活的定制与业务操作集成的流程工作流。
                </p>
                <h3>解决方案的功能：</h3>
                <ul className="flex-list">
                  <li>事件管理</li>
                  <li>服务请求</li>
                  <li>问题管理</li>
                  <li>变更管理</li>
                  <li>知识管理</li>
                  <li>服务目录</li>
                  <li>配置管理</li>
                  <li>资产管理和发现</li>
                  <li>项目管理</li>
                  <li>无代码的管理配置</li>
                  <li>全局搜索</li>
                  <li>集成</li>
                  <li>多语言</li>
                  <li>高级报表分析</li>
                  <li>远程支持</li>
                </ul>
              </div>
            </section>
            <section id="section-1">
              <div className="ism-core-content">
                <h2>{coreNav[1]}</h2>
                <h4>
                  将工单的接收分配、分类和管理集成到一个易于使用的统一界面中，实现更高效的工单解决方案。
                </h4>
                <h3>优化并促成事件解决方案</h3>
                <p>
                  智能服务管理通过对事件进行分类，来识别可能未发现的服务和有问题的配置项。通过查找智能服务管理的配置管理数据库（CMDB），可以很容易地识别每个事件的配置项。
                </p>
                <p>
                  当问题可能关联其他工单、配置项或问题时，服务等级协议（SLA）就将与问题相关的服务等级责任项关联到事件上。
                </p>
                <p>
                  为了提高遵从性，那些违反SLA的遗留事件将逐步升级。使用智能服务管理的全局搜索很容易定位到事件并且找到解决方案。
                </p>
                <h4>更大的可见性和透明度</h4>
                <p>
                  智能服务管理通过对事件跟踪，让用户和管理层始终保持对事件的知情。当事件状态发生变化以及事件解决时，它会自动通知用户。用户可以通过web或手机移动端与其他工作人员进行协作，并提供有价值的反馈。
                </p>
                <p>
                  智能服务管理预置了仪表盘和报表，通过以下方式管理和跟踪事件：
                </p>
                <ul>
                  <li>在问题中定义模式</li>
                  <li>成本分析</li>
                  <li>提供人员绩效数据</li>
                  <li>SLA完成情况报表</li>
                </ul>
                <h3>易于定制</h3>
                <p>可以使用集成的JasperSoft™报表工具创建自定义视图。可以：</p>
                <ul>
                  <li>查看事件动态</li>
                  <li>查看配置项是否已报告故障</li>
                  <li>查看每个事件从创建到最后关闭的成本</li>
                </ul>
                <div className="image-large">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic0a.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-2">
              <div className="ism-core-content">
                <h2>{coreNav[2]}</h2>
                <h4>为您的用户提供快速、轻松的标准化服务。</h4>
                <h3>标准化服务</h3>
                <p>
                  服务请求，如重置密码、请求配件等，应该标准化从而通过一个简单的请求来实现。标准化服务必须是可重复的且低风险的，这样就不需要升级审批，并尽可能快地满足这些预定义的标准服务请求。
                </p>
                <p>
                  服务请求的执行减少诸多不必要的细节，有助于降低服务的成本。合并流程提高了对这些服务的控制水平。所有这些旨在降低总成本和提高客户满意度。
                </p>
                <div className="image-large">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic0b.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-3">
              <div className="ism-core-content">
                <h2>{coreNav[3]}</h2>
                <h4>
                  预防、隔离和消除重复出现的问题，以确保您的业务能够顺利运行。
                </h4>
                <h3>改善IT组织的状况</h3>
                <p>
                  智能服务管理的问题管理，让您的服务台更加高效。基于ITIL行业最佳实践的工具帮助您：
                </p>
                <p>确定潜在问题</p>
                <p>修复问题，防止事件再次发生</p>
                <p>管理将会导致事件的问题</p>
                <h3>消除重复出现的事件</h3>
                <p>
                  智能服务管理的问题管理工具专注于消除重复发生的事件。使用经过ITIL验证的最佳实践，方便的管理问题生命周期，防止事件再次发生。
                </p>
                <p>
                  如果多个事件显示出共同的症状，或者单个事件没有已知的原因，它们就会被视为问题。通过工具的根因分析过程可以：
                </p>
                <ul>
                  <li>分类现有设备或服务存在的或潜在的问题</li>
                  <li>诊断问题</li>
                  <li>管理导致问题关闭的各方面因素</li>
                </ul>
                <h3>快速解决</h3>
                <p>
                  当需要变更来解决问题时，就会生成一个变更工单。对于关键问题的修复，可以在解决问题工单之前实施变更，以尽可能加快问题解决速度。
                </p>
                <h3>无缝集成</h3>
                <p>
                  任何ITSM都需要事件的快速、定性解决，避免问题再次发生。智能服务管理与其他七个ITIL功能无缝集成事件和问题管理，为服务台提供一流的解决方案：
                </p>
                <ul>
                  <li>
                    通过快速解决、准确跟踪和基于SLA的升级，实现更高的服务等级协议（SLA）
                  </li>
                  <li>
                    通过更好的事件分类、路径选择、可见性和自动升级，降低平均修复时间（MTTR）
                  </li>
                  <li>降低事故级别从而持续降低成本</li>
                  <li>座席人员更高效，减少员工人数从而降低服务成本</li>
                </ul>
                <div className="image-large">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic0c.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-4">
              <div className="ism-core-content">
                <h2>{coreNav[4]}</h2>
                <h4>
                  协同变更计划推出，用经过验证的最佳实践执行变更，以提高速度和准确性
                </h4>
                <h3>提高变更的有效性</h3>
                <p>
                  错误的和未管理的变更占所有意外停机的60%。变更的速度、风险和影响取决于对其进行的影响分析、计划和自动化的程度。
                </p>
                <p>
                  ISM的经过ITIL验证的变更管理提供了关键的影响分析和规划能力。
                </p>
                <p>
                  现在，您可以确保变更是经过计划、沟通和安排的。您可以确定政策和法规在实施之前得到了遵循、审查和批准。
                </p>
                <h3>计划推出和变更执行</h3>
                <p>
                  利用ISM流程自动化所有功能，可以确保使用经过测试和验证的流程流来执行变更。这减少了风险，并确保了政策和程序的一致性和遵从性，以减少停机时间。
                </p>
                <h3>降低风险，履行SLA和合同义务</h3>
                <p>
                  ISM经过itil验证的变更管理功能可以无缝地管理变更过程。从变更请求开始，自动实施到变更结束，功能包括：
                </p>
                <ul>
                  <li>通过计划、批准、调度、沟通和自动化变更来降低风险</li>
                  <li>通过采用经过验证的自动化流程，减少停机时间</li>
                  <li>通过主动和自动的政策遵循，提高合约和SLA遵从性</li>
                </ul>
                <div className="image-s">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic3.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-5">
              <div className="ism-core-content">
                <h2>{coreNav[5]}</h2>
                <h4>
                  始终如一地获取知识并将其转化为可用的信息，以增强支持服务人员和客户的能力。
                </h4>
                <h3>提高组织的效率</h3>
                <p>
                  有效地使用和重用组织的知识和经验是IT中最强大的最佳实践之一。
                </p>
                <p>
                  ISM提供了ITIL认证的知识管理能力。这将确保您的IT专业人员的知识转化为对服务人员和用户都可用的答案和解决方案。
                </p>
                <h3>将知识转化为解决方案</h3>
                <p>
                  重用经过验证和授权的解决方案对于实现成熟的IT团队至关重要。这有助于：
                </p>
                <ul>
                  <li>降低整体成本</li>
                  <li>提供服务人员生产力</li>
                  <li>不必担忧缺乏有经验的服务人员</li>
                  <li>达成一致的服务水平协议（SLA）</li>
                </ul>
                <h3>更高的客户满意度</h3>
                <p>
                  知识管理确保政策、流程和工具都能处于适当的位置，以便始终如一地获取已知知识。
                </p>
                <h3>完整地集成</h3>
                <p>
                  知识管理解决方案完全集成到ITSM的功能中。它是为满足内部（服务人员）和外部（用户）的知识工作者的需要而构建的。
                </p>
                <p>
                  ISM的知识管理通过为每个用户提供正确的知识，改进了客户自服务的成功程度及其对业务的影响。
                </p>
                <h3>可伸缩地增长</h3>
                <p>
                  解决问题、提供支持和管理变更是基于知识的功能，这是提高利润率的关键。
                  <br />
                  知识管理支持：
                </p>
                <ul>
                  <li>
                    通过重用解决方案和经验丰富的资源，可实现低成本持续增长
                  </li>
                  <li>通过改善自助服务来降低成本，从而减少免打电话和开单</li>
                  <li>
                    通过立即应用已验证的解决方案，减少平均修复时间（MTTR）
                  </li>
                  <li>通过有效的自服务用户授权，提高客户满意度</li>
                </ul>
              </div>
            </section>
            <section id="section-6">
              <div className="ism-core-content">
                <h2>{coreNav[6]}</h2>
                <h4>合并和标准组织的服务，以便用户可以轻松地提交服务请求</h4>
                <h3>向更多用户发布IT服务和业务服务</h3>
                <p>
                  ISM提供了与数字商店和服务目录相同的直观视觉提示和图标。使用这种方法，用户不需要进行培训就可以找到他们需要的内容。不仅新增新的服务非常方便，而且用户可以快速访问。
                </p>
                <h3>更快、更好的客户体验</h3>
                <p>
                  数字商店和服务目录将业务和IT的服务整合在一起，通过相同的方法对用户提供支持。您的组织不仅可以更直观地为业务部门构建服务目录，也可以让IT构建服务目录，使得所有服务发布和访问的方式保持一致。
                </p>
                <p>
                  对于IT和业务部门来说，比以往任何时候都更容易支持用户。所有的服务部门都可以在同一个入口来发布和更新自己的服务。
                </p>
                <p>数字商店和服务目录支持你需要控制的一切，包括：</p>
                <ul>
                  <li>请求服务的权限</li>
                  <li>跟踪成本并处理投诉</li>
                  <li>后台的服务审批和服务提供</li>
                </ul>
                <h3>简化服务自动化</h3>
                <p>
                  ISM的开箱即用连接器通过自动化邮件或者软件下载使得组织内部提供服务变得很容易。外部供应提供服务也非常简单，只需与外部供应商连接即可，外部供应商就可以直接向每个用户提供服务。
                </p>
                <p>
                  由于每一个服务目录背后都有服务自动化功能的支持，对于没有开发人员的部门，也能非常快速和容易地自动化每个服务。
                </p>
                <div className="image-m">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic5.png"
                    alt=""
                  />
                </div>
                <h3>省时省力</h3>
                <p>
                  强大的搜索机制可以帮助用户快速找到他们需要即时反馈的工单，快速了解请求的更新。用户不需要知道哪个部门提供哪些服务，服务请求的时间变得更短。
                </p>
                <p>
                  将业务服务和IT服务统一到一个数字商店的门户使访问更加直观，降低了服务支持成本。
                  <br />
                  服务目录提供了：
                </p>
                <ul>
                  <li>
                    通过一个直观的数字店面，减轻用户的困惑，降低服务本，减少错误和人工支持
                  </li>
                  <li>更好的可见性、可访问性和可用性，提升客户满意度</li>
                  <li>通过服务自动化和单点管理，降低了总拥有成本（TCO）</li>
                </ul>
              </div>
            </section>
            <section id="section-7">
              <div className="ism-core-content">
                <h2>{coreNav[7]}</h2>
                <h4>
                  在所有资产之间建立和维护一致性，这样您就可以更好地计划、识别、控制、说明和验证配置项。
                </h4>
                <h3>了解您的资产配置及其对IT的影响</h3>
                <p>现在，ISM提供了成功支持和管理IT设备和服务所需的配置数据。</p>
                <p>利用服务资产和配置管理(SACM)，一个关键的工具有助于：</p>
                <ul>
                  <li>解决事件</li>
                  <li>确定问题的根源</li>
                  <li>评估变更的影响</li>
                  <li>构建服务目录</li>
                </ul>
                <h3>全面跟踪和评估</h3>
                <p>
                  经过ITIL验证的功能模块使用强大的配置管理数据库(CMDB)中创建的信息。CMDB提供了工单、变更和对管理至关重要的资产之间的连接关系和关联关系。
                </p>
                <p>
                  SACM模块管理资产的货币价值、库存水平，以保证资产的库存。SACM模块也维护位置信息、每个资产之间的依赖关系，以及对每个资产进行适当的变更控制。
                </p>
                <p>使用这些信息，您可以：</p>
                <ul>
                  <li>确保资产信息仅由授权的个人才能更改</li>
                  <li>确保变更按照正确的时间表来执行</li>
                  <li>通过审核和审查，确保变更能得到验证</li>
                </ul>
                <p>该模块支持七个对IT管理至关重要的核心用例：</p>
                <ul className="flex-list">
                  <li>审计和合规</li>
                  <li>变更治理</li>
                  <li>影响分析</li>
                  <li>资源优化</li>
                  <li>根本原因定位</li>
                  <li>服务映射</li>
                  <li>服务绩效规划</li>
                </ul>
                <p>
                  ISM灵活的CMDB也帮助您满足业务需求。采用相同的方法，你可以：
                </p>
                <ul>
                  <li>跟踪服务目录生成的新资产</li>
                  <li>跟踪分配给员工的资产</li>
                  <li>评估设备变更或搬迁的影响</li>
                </ul>
                <h3>端到端的管理</h3>
                <p>
                  通过全生命周期的资产管理和支持，SACM模块支持核心IT功能。此外，CMDB与变更管理、服务目录、知识管理和自动化流程完全集成，为跨组织的端到端管理提供支持。您将获得：
                </p>
                <ul>
                  <li>由于准确的根本原因分析，缩短平均修复时间（MTTR）</li>
                  <li>通过更精确的变更影响分析，减少风险</li>
                  <li>
                    通过对库存（版本、存量和型号）的准确了解，改进服务水平协议（sla）和审计遵从性
                  </li>
                  <li>经过资产优化和更严格的库存管理，降低库存成本</li>
                </ul>
                <div className="image-s">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic2.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-8">
              <div className="ism-core-content">
                <h2>{coreNav[8]}</h2>
                <h4>
                  通过资产管理模块来监管企业的硬件设备、软件、供应商信息、采购等
                </h4>
                <h3>发现、监管、审计你的IT资产</h3>
                <p>
                  连续的自动发现及自动更新、有效管理资产管理库，可以使资产数据保持最新状态，从而有效支持其他支持服务，变更控制，审计及库存的可视性。
                </p>
                <p>
                  智能服务管理(ISM)能帮助您的IT资产在整个企业中占有重要的地位，这个资产包括服务器、个人笔记本电脑、台式机、虚拟机、操作系统、应用软件及其他网络相关的各种各样的资产。
                </p>
                <h3>整体管理</h3>
                <p>
                  在智能服务管理(ISM)的IT服务管理(ITSM)解决方案中，资产管理与其他工单系统、变更管理、服务请求管理及自动化都是紧密结合在一起的，这使得资产项出现在企业的方方面面、参与几乎所有的IT事务。
                </p>
                <p>
                  例如，当一个用户创建了一个工单，在处理时需要参考该用户使用或管理的所有资产项，如果能了解该用户关联的资产信息(功能、服务)的话，可以：
                </p>
                <ul>
                  <li>工单信息不需要很详细</li>
                  <li>更精确地处理问题并找到问题的解决方案</li>
                  <li>可以通过自动化处理</li>
                </ul>
                <p>
                  智能服务管理(ISM)可以持续地发现和管理资产，以提高IT的管理能力：
                </p>
                <ul>
                  <li>管理和控制库存</li>
                  <li>更精确地评估变更的影响</li>
                  <li>更快速、更高效地解决故障、问题</li>
                </ul>
                <p>
                  标准统一的资产数据模型可以帮助企业建立和管理庞大且复杂的IT环境，给企业带来一下的好处：
                </p>
                <ul>
                  <li>
                    通过对资产数据的准确且及时的维护，可以有效减少软件、硬件、支持等的成本。
                  </li>
                  <li>通过实施自动化，有效减少整体服务成本(TCO)</li>
                  <li>
                    通过查看受影响的资产信息，可以有效提供平均解决时间(MTTR)
                  </li>
                </ul>
              </div>
            </section>
            <section id="section-9">
              <div className="ism-core-content">
                <h2>{coreNav[9]}</h2>
                <h4>
                  项目管理可以管理项目交付物、项目任务、项目资源、项目周期等，协调工作以实现项目既定的目标及SLA
                </h4>
                <h3>把问题当项目来处理</h3>
                <p>
                  当一个问题或任务处理起来需要数小时才能解决或完成，或者需要很多人参与，或者需要很多步骤才能完成的时候，可以把它当成项目来处理：
                </p>
                <ul>
                  <li>成果会显著提高</li>
                  <li>提高SLA</li>
                  <li>管理可视化</li>
                </ul>
                <p>
                  另外，当任何一项工作任务是需要制定正式计划并严格按照此计划执行时，这时，就可以使用ISM的基于ITIL的项目管理。
                </p>
                <h3>完成集成服务功能</h3>
                <p>
                  智能服务管理(ISM)能帮助你的服务管理流程更具计划性，反之则缺乏计划性。
                </p>
                <p>
                  例如，如果你有一个很复杂的变更情况要做，那么通过项目管理可以更好的来管理整个变更的进度和任务，通过创建一个项目并且把配置项关联进来，我们就可以无缝地把变更、配置项数据、项目计划等整合在一起。
                </p>
                <p>
                  当一个重大任务需要众多人员参与完成时，那么一个详尽的、可视的、需要监管的计划是非常重要的。使用项目管理模块，你只需要计划好你的项目，然后去及时更新计划和数据就可以了，这可以带来：
                </p>
                <ul>
                  <li>
                    通过项目管理中项目计划可视化(对管理层)，提高项目的完成效率及SLA
                  </li>
                  <li>通过对人力资源的监管，提高成本利用效率及监管</li>
                  <li>
                    通过对项目历史信息的大数据分析，为将来项目的更精确的确定预算和周期。
                  </li>
                </ul>
                <div className="image-large">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic1.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-10">
              <div className="ism-core-content">
                <h2>{coreNav[10]}</h2>
                <h4>
                  新增服务项、自动化变更、工作流、审批或服务目录，统统都不需要写一行代码
                </h4>
                <h3>降低管理员成本</h3>
                <p>
                  智能服务管理可以快速、简单、无编码地新增服务、自动化变更等，通常情况下，一名业务人员即可作为ISM的系统管理员，这可以极大地减少运营成本及提高运维效率。
                </p>
                <h3>功能全面的服务台</h3>
                <p>
                  智能服务管理支持开箱即用的ITIL
                  9大流程，在无需任何编码的情况下使用到ITIL的最佳实践。
                </p>
              </div>
            </section>
            <section id="section-11">
              <div className="ism-core-content">
                <h2>{coreNav[11]}</h2>
                <h4>
                  支持快速、准确查找各种工单、知识文章、服务目录项、配置项等
                </h4>
                <h3>通过上下文感知快速搜索</h3>
                <p>
                  在当今数据知识经济时代，如果手里能得到足够多的知识数据信息，将使人更加强大。
                </p>
                <p>
                  智能服务管理（ISM）提供了一种简单易用、灵活的全局搜索功能，就像在ITSM中，你可以在搜索中定义各种过滤器，快速地找到你需要的东西。
                </p>
                <h3>增强搜索能力</h3>
                <p>
                  使用传统的搜索引擎，必须要确切的定义出你要搜索的内容，比如它的拼写，什么类型的工单，什么类型的资产，何时创建的工单等。
                </p>
                <p>
                  但是，智能服务管理（ISM）是基于最先进的智能搜索引擎，你不需要输入你想搜索的名字，通过上下文感知的下拉列表选择就可以了。
                </p>
                <h3>最大化使用最好的资产 —— 知识</h3>
                <p>
                  智能服务管理可以让你非常简单地创建知识、使用知识、维护知识文章，以及快速地查找你需要的知识解决方案信息。使用我们的平台，您可以得到如下的好处：
                </p>
                <ul>
                  <li>通过快速搜索到现存的问题，提高平均解决时间</li>
                  <li>通过快速使用知识文章，削减运维成本</li>
                  <li>
                    通过搜索到并使用历史问题的解决方案及处理过程，降低风险
                  </li>
                </ul>
                <div className="image-large">
                  <img
                    src="http://image-serviceai.test.upcdn.net/product/pd-ism-func-pic4.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
            <section id="section-12">
              <div className="ism-core-content">
                <h2>{coreNav[12]}</h2>
                <h4>
                  提升你的服务台，集成微信、钉钉、监控系统等
                  <br />
                  还可以通过Luma自动化实现跟1000多种应用进行集成
                </h4>
                <h3>整合应用孤岛，提升整体可见性</h3>
                <p>
                  应用烟囱化是阻碍一个组织整体可见性的问题之一。如何帮助组织实现信息无缝访问的系统互操作性是成功的关键。有了它，您可以向工程师提供尽可能多的相关客户信息。智能服务管理服务台为您提供您需要的整体可视性。它在整个数据环境中与无数的系统和资源交互，包括OA、CRM、ERP、SAP、Salesforce等。
                </p>
                <p>
                  智能服务管理服务台为您提供您需要的整体可视性。它在整个数据环境中与无数的系统和资源交互，通过Luma自动化连接器实现与各种应用之间的数据集成和交换，提升整体可见性。
                </p>
                <h3>增强客户体验</h3>
                <p>
                  整体的客户洞察力提高。客户服务体验的效率、个性化和整体质量，并相应地提高了客户满意度。
                </p>
                <p>
                  Luma服务管理提供了Application Program Interfaces
                  (APIs)，SOAP和Representational State Transfer
                  (REST)多种接口方式，便于集成第三方应用，包括如下组件：
                </p>
                <ul>
                  <li>开箱即用的应用连接器</li>
                  <li>外部系统集成</li>
                  <li>API</li>
                  <li>数据批量导入工具</li>
                </ul>
              </div>
            </section>
            <section id="section-13">
              <div className="ism-core-content">
                <h2>{coreNav[13]}</h2>
                <h4>
                  Luma服务管理预置了10种语言，并且可以配置时区来适应地球上不同地域的用户
                </h4>
                <h3>本地化和语言翻译能力</h3>
                <p>语言目录、屏幕名、菜单、字段名、描述和代码都是本地化的。</p>
                <p>
                  管理员可以很容易地更改它们。Luma服务管理支持输入和输出邮件中的关键字翻译，以及将搜索转换为用户选择的语言。如果数据使用所选择的语言，也可以用该语言生成报告。
                </p>
                <p>现在支持的语言：</p>
                <ul className="flex-list">
                  <li>🇬🇧 英语</li>
                  <li>🇨🇳 中文</li>
                  <li>🇯🇵 日语</li>
                  <li>🇪🇸 西班牙</li>
                  <li>🇫🇷 法语</li>
                  <li>🇩🇪 德语</li>
                  <li>🇧🇷 巴西葡萄牙语</li>
                  <li>🇷🇺 俄语</li>
                  <li>🇸🇪 瑞典</li>
                  <li>🇫🇮 芬兰</li>
                  <li>🇮🇹 意大利</li>
                </ul>
              </div>
            </section>
            <section id="section-14">
              <div className="ism-core-content">
                <h2>{coreNav[14]}</h2>
                <h4>
                  使用预先配置的、可定制的仪表板，用户将重点放在重要的事情上，这些仪表板可以提供快速而强大的洞察力。
                </h4>
                <h3>获得运营洞察力并改进战略分析</h3>
                <p>报告和分析是任何IT服务管理(ITSM)解决方案背后的大脑。</p>
                <p>
                  Luma服务管理可以让客户直接了解他们的ITSM使用情况。他们可以定制数据结构和报告，从而有效地交付洞察力——所有这些都不需要编写SQL。该解决方案使用JasperSoft™，一个商业智能领域的行业领导者。它提供了开箱即用的数据模型(域)，以及超过125个报告。
                </p>
                <h3>受益于业务智能能力</h3>
                <p>
                  报表和仪表板可以通过图形化界面进行快速组合和更新。有了这个最好的解决方案，你可以：
                </p>
                <ul>
                  <li>对业务服务与资源分配进行成本效益分析</li>
                  <li>
                    根据通常采取的行动或通常花费太长时间的行动，设计个人或组织的培训计划
                  </li>
                  <li>对事件执行基于阈值的分析，以检测可能的问题</li>
                  <li>监控个人、团队或企业的绩效指标</li>
                  <li>根据资源使用和任务频率，识别自动化的机会</li>
                  <li>基于工单的节奏和分类，推动敏捷目录开发</li>
                </ul>
              </div>
            </section>
            <section id="section-15">
              <div className="ism-core-content">
                <h2>{coreNav[15]}</h2>
                <h4>
                  在不离开办公室的情况下，通过聊天会话、屏幕共享和远程控制为客户提供个人支持
                </h4>
                <h3>安全的远程支持</h3>
                <p>
                  远程支持是一个完全集成的附加功能。对于需要一对一支持才能理解和解决问题的问题，您的客户可以使用远程访问和支持轻松地与代理协作，从而节省时间和成本。使用远程支持———聊天会话、屏幕共享和远程控制使支持分析人员和技术人员能够远程访问计算机，直接从工单视图了解和解决问题，而无需现场访问。远程会话被记录并与每个相应的工单一起存储。
                </p>
              </div>
            </section>
            <section id="section-16">
              <div className="ism-core-content">
                <h2>{coreNav[16]}</h2>
                <h3>多渠道访问</h3>
                <p>
                  通过web、微信、钉钉、移动应用、电子邮件、电话和虚拟服务助理等进行管理和整合—所有这些都通过一个单一的平台实现。
                </p>
                <h3>服务水平协议（SLA）管理</h3>
                <p>建立符合组织期望的规则和策略，同时获得对团队绩效的洞察。</p>
                <h3>事件管理</h3>
                <p>了解检测到的或预测的事件，以便采取最适当的纠正控制措施。</p>
                <h3>可用性管理</h3>
                <p>
                  确保应用程序、系统和基础设施在需要时可用，并在已建立的服务水平协议内使用。
                </p>
                <h3>身份和访问管理</h3>
                <p>
                  使用诸如Microsoft Active Directory、Oracle
                  AMO之类的统一身份认证服务，控制客户、最终用户和支持分析人员对服务台/管理的访问。
                </p>
                <h3>便笺本</h3>
                <p>
                  使用请求者的联系信息、票据日志和相关配置项的快照快速响应请求。
                </p>
                <h3>个性化的支持</h3>
                <p>
                  将部门、团队、地点和客户设置为独立的子组织。包括专门的服务提供、流程工作流、支持分析师分配、服务水平协议和批准。
                </p>
              </div>
            </section>
          </div>
        </div>
        <div className="ism-core-side-nav">
          <div className="content-wrap">
            <div className="ism-core-side-nav-card">
              <Link
                to="/products/product-ism/#pd-ism-core"
                className="nav-back"
              >
                Luma 服务管理
              </Link>
              <div className="toggle-side-menu" onClick={this.toggleSideMenu}>
                {this.state.showSideMenu ? "收起目录" : "展开目录"}
              </div>
              <div
                className={
                  this.state.showSideMenu
                    ? "ism-core-func-nav show"
                    : "ism-core-func-nav"
                }
              >
                <Scrollspy
                  items={[
                    "section-0",
                    "section-1",
                    "section-2",
                    "section-3",
                    "section-4",
                    "section-5",
                    "section-6",
                    "section-7",
                    "section-8",
                    "section-9",
                    "section-10",
                    "section-11",
                    "section-12",
                    "section-13",
                    "section-14",
                    "section-15",
                    "section-16",
                  ]}
                  currentClassName="is-current"
                >
                  {coreNav.map((section, index) => (
                    <li key="section">
                      <Link
                        to={"/products/product-ism-core/#section-" + index}
                        onClick={this.closeSideMenu}
                      >
                        {section}
                      </Link>
                    </li>
                  ))}
                </Scrollspy>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IsmCore
